@font-face {
  font-family: 'nether';
  src: local('nether'), url(./fonts/nether-1/nether.ttf) format('truetype');
}

html {
  /*height: 100%;*/
  background-color: #eaeaea;
  min-height: 100vh;
}

.home-page {
  /*display: flex;*/
  /*flex-direction: column;*/
}

footer {
  opacity: 0.5;
  width: 100%;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%,-50%);
}

.search-icon-svg {
  opacity: .5;
}

.home-subscribe-icons {
  margin-top: 11px;
  margin-right: 20px;
  display: flex;
  flex-direction: row-reverse;
  gap: 17px;
}

.carousel-img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.carousel-indicators {
  gap: 20px;
}

.carousel-indicators [data-bs-target] {
  border-radius: 50%;
  width: 5px !important;
  height: 5px !important;
}

.home-header {
  background-color: #6a6a6a;
  width: 100vw;
  height: 60px;
  position: relative;
}

.home-container {
  background-color: #eaeaea;
}

.home-carousel-container {
  padding-bottom: 55px;
}

.home-carousel {
  width: 80%;
  margin: 55px auto 0 auto;
}

.home-carousel h3{
  font-family: "Open Sans";
  font-size: 17px;
  letter-spacing: 2px;
  font-weight: bold;
}

.home-header-h2 {
  font-family: "Open Sans";
  font-weight: 500;
  letter-spacing: 2px;
  color: #eaeaea;
  font-size: 22px;
  position: absolute;
  left: 50px;
  top: 50%;
  margin: 0;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

.home-header-h1 {
  font-family: "Open Sans";
  letter-spacing: 2px;
  font-weight: bold;
  color: #eaeaea;
  font-size: 22px;
  position: absolute;
  margin: 0 auto 0 auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.home-header-p {
  font-family: "Open Sans";
  letter-spacing: 2px;
  font-weight: bold;
  color: #eaeaea;
  font-size: 10px;
  margin: 0 auto 0 auto;
  transform: translate(0%, 50%);
  text-align: center;
}

.home-title-div {
  padding-top: 68px;
  padding-left: 20px;
  width: 100vw;
}

.home-title-div-h1 {
  width: 500px;
  font-family: "Open Sans";
  font-weight: bold;
  letter-spacing: 10px;
  text-align: center;
  margin: 0 auto 0 auto;
}

.home-title-div-h2 {
  width: 200px;
  font-size: 13px;
  letter-spacing: 2px;
  font-weight: bold;
  font-family: nether;
  text-align: center;
  margin: 10px auto 0 auto;
}

.home-nav-container {
  width: 90vw;
  font-size: 13px;
  margin: 60px auto 0 auto;
  display: flex;
  border-bottom: 1px solid;
  border-bottom-color: rgb(0, 0, 0, .5);
  justify-content: center;
}

.home-nav-container-div {
  margin-top: auto;
  margin-bottom: auto;
  box-shadow: 1px 1px 1px #8b8b8b;
  width: 170px;
}

.home-nav-container-div-input {
  height: 20px;
  color: #8b8b8b;
  margin: auto 0 auto 0;
  outline: none;
  border: none;
}

/*ホーム画面のナビゲーションバー*/
.navbar {
  font-family: "Open Sans";
  width: 800px;
  height: 50px;
  padding: 0;
}
.nav {
  padding: 0;
  display: flex;
  justify-content: center;
}
.navbar ul {
  display: flex;
  align-items: center;
  width: 100%;
  list-style: none;
}
.navbar ul li {
  margin-left: 5%;
  margin-right: 5%;
  text-align: center;
}
.nav-item a {
  color: #000;
  font-size: 13px;
  text-decoration: none;
  transition: color 0.3s ease-out;
}
.nav-item a:hover {
  color: pink;
}
/*ホーム画面のナビゲーションバー*/

.doyo-hoge-icon-img {
  width: 13%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

@media screen and (max-width: 1024px) {
  .home-title-div {
    padding-top: 68px;
    padding-left: 0px;
    width: 100vw;
  }
  /*ホーム画面のヘッダー*/
  .home-header {
    background-color: #6a6a6a;
    width: 100vw;
    height: 50px;
    position: relative;
  }

  .home-header-h1 {
    width: 100%;
    font-family: "Open Sans";
    letter-spacing: 2px;
    font-weight: 400;
    color: #eaeaea;
    font-size: 12px;
    text-align: center;
    margin: 0 auto 0 auto;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
  }
  /*ホーム画面のヘッダー*/

  /*ホーム画面のロゴ*/
  .doyo-hoge-icon-img {
    width: 32%;
    margin-left: auto;
    /*text-align: center;*/
    margin-right: auto;
    display: block;
    /*display: flex;*/
    /*align-items: center;*/
  }

  /*ホーム画面のナビゲーションバー*/
  .nav {
    padding: 0;
  }

  .navbar {
    font-family: "Open Sans";
    /*width: 100%;*/
    height: 50px;
    padding: 0;
  }
  .navbar ul {
    display: flex;
    align-items: center;
    width: 100%;
    list-style: none;
  }
  .navbar ul li {
    width: 15%;
    margin-left: 2%;
    margin-right: 2%;
  }
  .nav-item a {
    color: #000;
    font-size: 10px;
    text-decoration: none;
    transition: color 0.3s ease-out;
  }
  .nav-item a:hover {
    color: pink;
  }
  .home-nav-container-div {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 1px 1px 1px #8b8b8b;
    width: 50%;
  }
  .home-nav-container-div-input {
    height: 20px;
    width: 100%;
    color: #8b8b8b;
    margin: auto 0 auto 0;
    outline: none;
    border: none;
  }
}

@media screen and (max-width: 912px) {
  .home-title-div {
    padding-top: 68px;
    padding-left: 0px;
    width: 100vw;
  }
  /*ホーム画面のヘッダー*/
  .home-header {
    background-color: #6a6a6a;
    width: 100vw;
    height: 50px;
    position: relative;
  }

  /*.home-header-h2 {*/
  /*  font-family: "Open Sans";*/
  /*  font-weight: bold;*/
  /*  letter-spacing: 2px;*/
  /*  color: #eaeaea;*/
  /*  font-size: 14px;*/
  /*  position: absolute;*/
  /*  left: 20px;*/
  /*  top: 50%;*/
  /*  margin: 0;*/
  /*  transform: translateY(-50%);*/
  /*  -webkit-transform: translateY(-50%);*/
  /*  -ms-transform: translateY(-50%);*/
  /*}*/

  .home-header-h1 {
    width: 100%;
    font-family: "Open Sans";
    letter-spacing: 2px;
    font-weight: 400;
    color: #eaeaea;
    font-size: 13px;
    text-align: center;
    margin: 0 auto 0 auto;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
  }
  /*ホーム画面のヘッダー*/

  /*ホーム画面のロゴ*/
  .doyo-hoge-icon-img {
    width: 32%;
    margin-left: auto;
    /*text-align: center;*/
    margin-right: auto;
    display: block;
    /*display: flex;*/
    /*align-items: center;*/
  }

  /*ホーム画面のナビゲーションバー*/
  .nav {
    padding: 0;
  }

  .navbar {
    font-family: "Open Sans";
    /*width: 100%;*/
    height: 50px;
    padding: 0;
  }
  .navbar ul {
    display: flex;
    align-items: center;
    width: 100%;
    list-style: none;
  }
  .navbar ul li {
    width: 15%;
    margin-left: 2%;
    margin-right: 2%;
  }
  .nav-item a {
    color: #000;
    font-size: 10px;
    text-decoration: none;
    transition: color 0.3s ease-out;
  }
  .nav-item a:hover {
    color: pink;
  }
  .home-nav-container-div {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 1px 1px 1px #8b8b8b;
    width: 50%;
  }
  .home-nav-container-div-input {
    height: 20px;
    width: 100%;
    color: #8b8b8b;
    margin: auto 0 auto 0;
    outline: none;
    border: none;
  }
}

@media screen and (max-width: 820px) {
  .home-title-div {
    padding-top: 68px;
    padding-left: 0px;
    width: 100vw;
  }
  /*ホーム画面のヘッダー*/
  .home-header {
    background-color: #6a6a6a;
    width: 100vw;
    height: 50px;
    position: relative;
  }

  /*.home-header-h2 {*/
  /*  font-family: "Open Sans";*/
  /*  font-weight: bold;*/
  /*  letter-spacing: 2px;*/
  /*  color: #eaeaea;*/
  /*  font-size: 14px;*/
  /*  position: absolute;*/
  /*  left: 20px;*/
  /*  top: 50%;*/
  /*  margin: 0;*/
  /*  transform: translateY(-50%);*/
  /*  -webkit-transform: translateY(-50%);*/
  /*  -ms-transform: translateY(-50%);*/
  /*}*/

  .home-header-h1 {
    width: 100%;
    font-family: "Open Sans";
    letter-spacing: 2px;
    font-weight: 400;
    color: #eaeaea;
    font-size: 13px;
    text-align: center;
    margin: 0 auto 0 auto;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
  }
  /*ホーム画面のヘッダー*/

  /*ホーム画面のロゴ*/
  .doyo-hoge-icon-img {
    width: 32%;
    margin-left: auto;
    /*text-align: center;*/
    margin-right: auto;
    display: block;
    /*display: flex;*/
    /*align-items: center;*/
  }

  /*ホーム画面のナビゲーションバー*/
  .nav {
    padding: 0;
  }

  .navbar {
    font-family: "Open Sans";
    /*width: 100%;*/
    height: 50px;
    padding: 0;
  }
  .navbar ul {
    display: flex;
    align-items: center;
    width: 100%;
    list-style: none;
  }
  .navbar ul li {
    width: 15%;
    margin-left: 2%;
    margin-right: 2%;
  }
  .nav-item a {
    color: #000;
    font-size: 10px;
    text-decoration: none;
    transition: color 0.3s ease-out;
  }
  .nav-item a:hover {
    color: pink;
  }
  .home-nav-container-div {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 1px 1px 1px #8b8b8b;
    width: 50%;
  }
  .home-nav-container-div-input {
    height: 20px;
    width: 100%;
    color: #8b8b8b;
    margin: auto 0 auto 0;
    outline: none;
    border: none;
  }
}

@media screen and (max-width: 412px) {
  .home-title-div {
    padding-top: 68px;
    padding-left: 0px;
    width: 100vw;
  }
  /*ホーム画面のヘッダー*/
  .home-header {
    background-color: #6a6a6a;
    width: 100vw;
    height: 50px;
    position: relative;
  }

  .home-header-h2 {
    font-family: "Open Sans";
    font-weight: bold;
    letter-spacing: 2px;
    color: #eaeaea;
    font-size: 14px;
    position: absolute;
    left: 20px;
    top: 50%;
    margin: 0;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
  }

  .home-header-h1 {
    width: 100%;
    font-family: "Open Sans";
    letter-spacing: 2px;
    font-weight: 400;
    color: #eaeaea;
    font-size: 10px;
    text-align: center;
    margin: 0 auto 0 auto;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
  }
  /*ホーム画面のヘッダー*/

  /*ホーム画面のロゴ*/
  .doyo-hoge-icon-img {
    width: 32%;
    margin-left: auto;
    /*text-align: center;*/
    margin-right: auto;
    display: block;
    /*display: flex;*/
    /*align-items: center;*/
  }

  /*ホーム画面のナビゲーションバー*/
  .nav {
    padding: 0;
  }

  .navbar {
    font-family: "Open Sans";
    /*width: 100%;*/
    height: 50px;
    padding: 0;
  }
  .navbar ul {
    display: flex;
    align-items: center;
    width: 100%;
    list-style: none;
  }
  .navbar ul li {
    width: 15%;
    margin-left: 2%;
    margin-right: 2%;
  }
  .nav-item a {
    color: #000;
    font-size: 10px;
    text-decoration: none;
    transition: color 0.3s ease-out;
  }
  .nav-item a:hover {
    color: pink;
  }
  .home-nav-container-div {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 1px 1px 1px #8b8b8b;
    width: 50%;
  }
  .home-nav-container-div-input {
    height: 20px;
    width: 100%;
    color: #8b8b8b;
    margin: auto 0 auto 0;
    outline: none;
    border: none;
  }
}

@media screen and (max-width: 390px) {
  .home-title-div {
    padding-top: 68px;
    padding-left: 0px;
    width: 100vw;
  }
  /*ホーム画面のヘッダー*/
  .home-header {
    background-color: #6a6a6a;
    width: 100vw;
    height: 50px;
    position: relative;
  }

  .home-header-h2 {
    font-family: "Open Sans";
    font-weight: bold;
    letter-spacing: 2px;
    color: #eaeaea;
    font-size: 14px;
    position: absolute;
    left: 20px;
    top: 50%;
    margin: 0;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
  }

  .home-header-h1 {
    width: 100%;
    font-family: "Open Sans";
    letter-spacing: 2px;
    font-weight: 400;
    color: #eaeaea;
    font-size: 10px;
    text-align: center;
    margin: 0 auto 0 auto;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
  }
  /*ホーム画面のヘッダー*/

  /*ホーム画面のロゴ*/
  .doyo-hoge-icon-img {
    width: 32%;
    margin-left: auto;
    /*text-align: center;*/
    margin-right: auto;
    display: block;
    /*display: flex;*/
    /*align-items: center;*/
  }

  /*ホーム画面のナビゲーションバー*/
  .nav {
    padding: 0;
  }

  .navbar {
    font-family: "Open Sans";
    /*width: 100%;*/
    height: 50px;
    padding: 0;
  }
  .navbar ul {
    display: flex;
    align-items: center;
    width: 100%;
    list-style: none;
  }
  .navbar ul li {
    width: 15%;
    margin-left: 2%;
    margin-right: 2%;
  }
  .nav-item a {
    color: #000;
    font-size: 10px;
    text-decoration: none;
    transition: color 0.3s ease-out;
  }
  .nav-item a:hover {
    color: pink;
  }
  .home-nav-container-div {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 1px 1px 1px #8b8b8b;
    width: 50%;
  }
  .home-nav-container-div-input {
    height: 20px;
    width: 100%;
    color: #8b8b8b;
    margin: auto 0 auto 0;
    outline: none;
    border: none;
  }
}

@media screen and (max-width: 280px) {
  .home-title-div {
    padding-top: 68px;
    padding-left: 0px;
    width: 100vw;
  }
  /*ホーム画面のヘッダー*/
  .home-header {
    background-color: #6a6a6a;
    width: 100vw;
    height: 50px;
    position: relative;
  }

  /*.home-header-h2 {*/
  /*  font-family: "Open Sans";*/
  /*  font-weight: bold;*/
  /*  letter-spacing: 2px;*/
  /*  color: #eaeaea;*/
  /*  font-size: 14px;*/
  /*  position: absolute;*/
  /*  left: 20px;*/
  /*  top: 50%;*/
  /*  margin: 0;*/
  /*  transform: translateY(-50%);*/
  /*  -webkit-transform: translateY(-50%);*/
  /*  -ms-transform: translateY(-50%);*/
  /*}*/

  .home-header-h1 {
    width: 100%;
    font-family: "Open Sans";
    letter-spacing: 2px;
    font-weight: 400;
    color: #eaeaea;
    font-size: 5px;
    text-align: center;
    margin: 0 auto 0 auto;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
  }
  /*ホーム画面のヘッダー*/

  /*ホーム画面のロゴ*/
  .doyo-hoge-icon-img {
    width: 32%;
    margin-left: auto;
    /*text-align: center;*/
    margin-right: auto;
    display: block;
    /*display: flex;*/
    /*align-items: center;*/
  }

  /*ホーム画面のナビゲーションバー*/
  .nav {
    padding: 0;
  }

  .navbar {
    font-family: "Open Sans";
    /*width: 100%;*/
    height: 50px;
    padding: 0;
  }
  .navbar ul {
    display: flex;
    align-items: center;
    width: 100%;
    list-style: none;
  }
  .navbar ul li {
    width: 15%;
    margin-left: 2%;
    margin-right: 2%;
  }
  .nav-item a {
    color: #000;
    font-size: 10px;
    text-decoration: none;
    transition: color 0.3s ease-out;
  }
  .nav-item a:hover {
    color: pink;
  }
  .home-nav-container-div {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 1px 1px 1px #8b8b8b;
    width: 50%;
  }
  .home-nav-container-div-input {
    height: 20px;
    width: 100%;
    color: #8b8b8b;
    margin: auto 0 auto 0;
    outline: none;
    border: none;
  }
}